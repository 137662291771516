import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import classnames from "classnames";
import { useResetPasswordForm } from "./useResetPasswordForm";
import { toastError } from "../../utils/toast";
import { useHistory } from "react-router-dom";

const ResetPassword = (props: any) => {
  const history = useHistory();
  const params = new URLSearchParams(props.location.search);
  const { register, onSubmit, errors } = useResetPasswordForm();

  useEffect(() => {
    if (!params.get("token")) {
      toastError(
        "Você precisa primeiro solicitar uma Recuperação de Senha para pode recuperá-lá."
      );
      history.push("/forgot");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Criar uma nova senha</small>
            </div>
            <Form role="form" onSubmit={onSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoFocus
                    placeholder="Digite sua nova senha"
                    type="password"
                    id="password"
                    name="password"
                    className={classnames({
                      "is-invalid": errors.password,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirme sua nova senha"
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    className={classnames({
                      "is-invalid": errors.password_confirmation,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.password_confirmation && (
                  <small className="text-danger">
                    {errors.password_confirmation.message}
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" block>
                  Redefinir Senha
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResetPassword;
