import React, { createContext, useState } from "react";

import { LoadingContextState } from "./types";

const contextDefaultValues: LoadingContextState = {
  loading: false,
  showLoader: () => {},
  hideLoader: () => {},
};

export const LoadingContext =
  createContext<LoadingContextState>(contextDefaultValues);

export const LoadingContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(contextDefaultValues.loading);

  const showLoader = (): void => {
    setLoading(true);
  };

  const hideLoader = (): void => {
    setLoading(false);
  };

  return (
    <LoadingContext.Provider
      value={{
        loading,
        showLoader,
        hideLoader,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
