import Customers from "./pages/Customers";
import { Establishments } from "./pages/Establishments";
import { Groups } from "./pages/Groups";
import Sales from "./pages/Sales";
import { Sellers } from "./pages/Sellers";
import Users from "./pages/Users";
import Profile from "./views/examples/Profile";

var adminUserRoutes = [
  {
    path: "/sales",
    name: "Vendas",
    icon: "fas fa-file-invoice-dollar text-success",
    component: Sales,
    layout: "/admin",
  },
  {
    path: "/establishments",
    name: "Estabelecimentos",
    icon: "fas fa-building text-dark",
    component: Establishments,
    layout: "/admin",
  },
  {
    path: "/sellers",
    name: "Vendedores",
    icon: "fas fa-users text-primary",
    component: Sellers,
    layout: "/admin",
  },
  {
    path: "/groups",
    name: "Unidade de Vendas",
    icon: "fas fa-user-friends text-info",
    component: Groups,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Clientes",
    icon: "fas fa-users text-primary",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Usuários",
    icon: "fas fa-users text-red",
    component: Users,
    layout: "/admin",
  },
];

var commonUserRoutes = [
  {
    path: "/sales",
    name: "Vendas",
    icon: "fas fa-file-invoice-dollar text-success",
    component: Sales,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
];

export { adminUserRoutes, commonUserRoutes };
