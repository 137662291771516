import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import React, { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { AuthContext } from "../../context/auth/AuthContext";
import { LoadingContext } from "../../context/loading/LoadingContext";
import { toastError } from "../../utils/toast";

interface LoginFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { login, error, isAuthenticated, clearErrors } =
    useContext(AuthContext);
  const history = useHistory();

  const LoginSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required("Email é obrigatório")
          .email("Por favor inclua um email válido"),
        password: yup
          .string()
          .required("Por favor, insira sua senha")
          /* .matches(
            /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "A senha deve conter pelo menos 6 caracteres, uma maiúscula, um número e um caractere especial"
          ), */
      }),
    []
  );

  const { register, handleSubmit, errors } = useForm<LoginFormData>({
    resolver: yupResolver(LoginSchema),
  });

  useEffect(() => {
    document.title = "Login | Paypi";
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      hideLoader();
      history.push("/admin/index");
    }
    if (error) {
      hideLoader();
      if (typeof error === "object") {
        error.forEach((e) => {
          toastError(e.msg);
        });
      } else {
        toastError(error);
      }

      clearErrors();
    }
  }, [history, error, isAuthenticated, clearErrors, hideLoader]);

  function onSubmit(data: LoginFormData) {
    showLoader();
    login(data);
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Faça seu login</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoFocus
                    placeholder="Digite seu email"
                    type="email"
                    autoComplete="new-email"
                    id="email"
                    name="email"
                    className={classnames({
                      "is-invalid": errors.email,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Digite sua senha"
                    type="password"
                    autoComplete="new-password"
                    id="password"
                    name="password"
                    className={classnames({
                      "is-invalid": errors.email,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" block>
                  Acessar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="forgot">
              <small>Esqueceu sua senha?</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link className="text-light" to="/register">
              <small>Criar uma conta</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
