import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isValidPhone } from "@brazilian-utils/brazilian-utils";
import { useHistory } from "react-router-dom";

import { CreateSellerFormData } from "./CreateSellerFormData";
import * as masks from "../../utils/Mask";
import useLoading from "../../hooks/useLoading";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";

export function useCreateSellerForm() {
  const history = useHistory();
  const { showLoader, hideLoader } = useLoading();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        idgroup: yup.string().required("Grupo econômico é obrigatório"),
        name: yup
          .string()
          .required("Nome é obrigatório")
          .matches(
            /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|\s]+$/,
            "Nome só pode conter letras"
          ),
        email: yup
          .string()
          .email("Por favor inclua um email válido")
          .required("Email é obrigatório"),
        mobile: yup
          .string()
          .required("Celular é obrigatório")
          .transform(masks.phoneMask.transform)
          .test("validatePhone", "Celular inválido", (value) => {
            if (!value) {
              return false;
            }

            return isValidPhone(value);
          }),
        birth_date: yup.string().required("Data de nascimento é obrigatório"),
      }),
    []
  );

  const { register, handleSubmit, errors } = useForm<CreateSellerFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (formValues: CreateSellerFormData) => {
      try {
        showLoader();

        await api.post("sellers", JSON.stringify(formValues), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess("Vendedor criado com sucesso");

        history.push("/admin/sellers");
      } catch (error) {
        hideLoader();
        let message = "";

        if (error.response.data.errors) {
          error.response.data.errors.forEach((e: any) => {
            toastError(e.msg);
          });
          return;
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Algo deu errado ao cadastrar o Vendedor";
        }

        toastError(message);
      }
    },
    [history, showLoader, hideLoader]
  );

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
}
