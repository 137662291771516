import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { AuthContext } from "../../context/auth/AuthContext";

const UserHeader = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h1 className="display-2 text-white">
                Olá {user && user.name.split(' ')[0]}
              </h1>
              <p className="text-white mt-0">Esta é a sua página de perfil.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
