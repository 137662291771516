import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-responsive-pagination";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import { LoadingContext } from "../../context/loading/LoadingContext";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";
import swal from "sweetalert";

import "./styles.scss";

interface Sale {
  idcontract: number;
  idpayment: number;
  name: string;
  company_name: string;
  amount_charged: number;
  installments: number;
  created_at: string;
  bg: string;
  status: string;
  statusCode: number;
}

interface IPayment {
  idpayment: number;
  amount_paid: number;
  installments: number;
  merchant_transaction_id: string;
  return_code: string;
  created_at: string;
  definition: string;
}

const LIMIT = 15;

const Sales: React.FC = (props: any) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [sales, setSales] = useState<Sale[]>([]);
  const [modal, setModal] = useState(false);
  const [pages, setPages] = useState(0);
  const [offset, setOffset] = useState(1);
  const [paymentInformation, setPaymentInformation] = useState({
    idcontract: 0,
    name: "",
    payments: [],
  });

  useEffect(() => {
    document.title = "Vendas | Paypi";

    async function getCustomers() {
      try {
        showLoader();
        const response = await api.get(`sales?page=${offset}&limit=${LIMIT}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        const totalPages = Math.ceil(response.data.totalItems / LIMIT);

        setPages(totalPages);

        setSales(response.data.sales);

        hideLoader();
      } catch (e) {
        hideLoader();
        toastError("Algo deu de errado ao buscar as vendas.");
      }
    }
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [offset]);

  const handleToggle = () => setModal(!modal);

  const handleClickToggle = async ({
    idcontract,
    name,
  }: {
    idcontract: number;
    name: string;
  }) => {
    try {
      const { data } = await api.get(`sales/${idcontract}/payments`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setPaymentInformation({
        idcontract: 0,
        name: "",
        payments: [],
      });

      setModal(!modal);
      setPaymentInformation({
        idcontract,
        name,
        payments: data,
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          if (error.response.data.message) {
            message = error.response.data.message;
          } else {
            message = "Algo deu errado ao buscar os pagamentos da venda.";
          }
        } else {
          message = "Algo deu errado ao buscar os pagamentos da venda.";
        }
      } else {
        message = "Algo deu errado ao buscar os pagamentos do contrato.";
      }

      hideLoader();
      toastError(message);
    }
  };

  function cancelTransation(idpayment: number) {
    swal({
      title: "Atenção",
      text: "Esta operação é irreversível. Seu cliente receberá um estorno no Cartão. Confirma o cancelamento?",
      icon: "warning",
      buttons: ["Não", "Sim"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const { data } = await api.post(
            "sales/cancel",
            JSON.stringify({
              idpayment,
            }),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );

          const newSales = sales.map((sale: Sale) => {
            if (sale.idpayment === idpayment) {
              return {
                ...sale,
                bg: "bg-primary",
                status: "Estornado",
                statusCode: 6,
              };
            }

            return sale;
          });

          setSales(newSales);

          hideLoader();

          toastSuccess(data.message);
        } catch (error) {
          hideLoader();
          toastError(
            "Não foi possível cancelar o pagamento, por favor tente novamente mais tarde"
          );
        }
      }
    });
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <div className="text-right">
              <Link
                className="btn btn-default mb-3 mt-2"
                to="/admin/sales/create"
              >
                <i className="fas fa-plus" /> Criar uma venda
              </Link>
            </div>

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Vendas</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col" className="row-ID">
                      Nº
                    </th>
                    <th scope="col" className="row-name">
                      Cliente
                    </th>
                    <th scope="col" className="row-name">
                      Empresa
                    </th>
                    <th scope="col" className="row-amount">
                      Valor
                    </th>
                    <th scope="col" className="row-installments">
                      Parcelas
                    </th>
                    <th scope="col" className="row-status">
                      Status
                    </th>
                    <th scope="col" className="row-data">
                      Ult. Atualização
                    </th>
                    <th scope="col" className="row-action">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((sale) => (
                    <tr key={sale.idcontract}>
                      <td>{sale.idcontract}</td>
                      <td>{sale.name}</td>
                      <td className="td-company-name">
                        <p
                          className="m-0 p-0"
                          style={{
                            fontSize: "inherit",
                            fontFamily: "inherit",
                            fontWeight: "inherit",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {sale.company_name}
                        </p>
                      </td>
                      <td>
                        {Number(sale.amount_charged).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td>{sale.installments}</td>
                      <td>
                        <span
                          className={`${sale.bg} text-white p-1 rounded text-center d-block w-100`}
                        >
                          {sale.status}
                        </span>
                      </td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-yellow" />
                          {moment(sale.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Badge>
                      </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {(sale.statusCode === 1 ||
                              sale.statusCode === 3 ||
                              sale.statusCode === 6 ||
                              sale.statusCode === 7) && (
                              <DropdownItem
                                onClick={() =>
                                  handleClickToggle({
                                    idcontract: sale.idcontract,
                                    name: sale.name,
                                  })
                                }
                              >
                                <span>
                                  <i className="fas fa-file-invoice-dollar" />{" "}
                                  Informações do Pagamento
                                </span>
                              </DropdownItem>
                            )}
                            {/* {sale.statusCode === 3 && (
                              <>
                                <DropdownItem
                                  onClick={() =>
                                    cancelTransation(sale.idpayment)
                                  }
                                >
                                  <span>
                                    <i className="fas fa-times" /> Cancelar
                                    Pagamento
                                  </span>
                                </DropdownItem>
                              </>
                            )} */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={pages}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggle}>
          Informações do Pagamento
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p className="mb-0">
                  <strong>Nº do Contrato</strong>:{" "}
                  {paymentInformation.idcontract}
                </p>
                <p>
                  <strong>Cliente</strong>: {paymentInformation.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="id">
                          ID
                        </th>
                        <th scope="col" className="amount">
                          Valor (R$)
                        </th>
                        <th scope="col" className="installments">
                          Parcelas
                        </th>
                        <th scope="col" className="installments">
                          Autorização
                        </th>
                        <th scope="col" className="created_at">
                          Criado
                        </th>
                        <th scope="col" className="status">
                          Status
                        </th>
                        <th scope="col" className="message">
                          Mensagem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentInformation.payments.map((payment: IPayment) => (
                        <tr key={payment.idpayment}>
                          <td className="id">{payment.idpayment}</td>
                          <td className="amount">
                            {Number(payment.amount_paid).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )}
                          </td>
                          <td className="installments">
                            {payment.installments}
                          </td>
                          <td className="installments">
                            {payment.merchant_transaction_id}
                          </td>
                          <td className="created_at">
                            {moment(payment.created_at).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </td>
                          <td className="status">{payment.return_code}</td>
                          <td className="message">{payment.definition}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Sales;
