import { formatCPF } from "@brazilian-utils/brazilian-utils";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Card,
  CardFooter,
  CardHeader,
  Container,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import Pagination from "react-responsive-pagination";
import { LoadingContext } from "../../context/loading/LoadingContext";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError } from "../../utils/toast";

interface ICustomers {
  idcustomer: number;
  cpf_cnpj: string;
  name: string;
}

const LIMIT = 15;

const Customers: React.FC = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [customers, setCustomers] = useState([]);
  const [pages, setPages] = useState(0);
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    document.title = "Clientes | Paypi";
  }, []);

  useEffect(() => {
    async function getCustomers() {
      showLoader();

      try {
        const { data } = await api.get(
          `customers?page=${offset}&limit=${LIMIT}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        const totalPages = Math.ceil(data.totalItems / LIMIT);

        setPages(totalPages);
        setCustomers(data.customers);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError("Ops, não foi possível lista os cliente.");
      }
    }

    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [offset]);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <div className="text-right">
              <Link
                className="btn btn-success mb-3 mt-2"
                to="/admin/customers/create"
              >
                <i className="fas fa-plus" /> Criar novo cliente
              </Link>
            </div>

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white">Clientes</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">CPF</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer: ICustomers) => (
                    <tr key={customer.idcustomer}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {formatCPF(customer.cpf_cnpj)}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{customer.name}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <Link
                              to={`/admin/customers/${customer.idcustomer}/sales`}
                              className="dropdown-item"
                              tabIndex={0}
                              role="menuitem"
                            >
                              <span>
                                <i className="fas fa-file-invoice" /> Vendas
                              </span>
                            </Link>
                            <Link
                              to={`/admin/customers/${customer.idcustomer}`}
                              className="dropdown-item"
                              tabIndex={0}
                              role="menuitem"
                            >
                              <span>
                                <i className="fas fa-edit" /> Editar
                              </span>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={pages}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Customers;
