import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ForgotFormData } from "./ForgotFormData";
import api from "../../services/api";
import { LoadingContext } from "../../context/loading/LoadingContext";
import { useHistory } from "react-router-dom";
import { toastError, toastSuccess } from "../../utils/toast";

export function useForgotForm() {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const history = useHistory();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("Por favor inclua um email válido")
          .required("Email é obrigatório"),
      }),
    []
  );

  const { register, handleSubmit, errors } = useForm<ForgotFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(async (formValues: ForgotFormData) => {
    try {
      showLoader();

      const data = JSON.stringify({
        email: formValues.email,
      });

      const response = await api.post("forgot", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      hideLoader();

      toastSuccess(response.data.message);

      history.push("/login");
    } catch (error) {
      hideLoader();

      let message = null;

      message = "Algo não deu certo, esse email existe?";

      if (error.response) {
        if (error.response.data.message) {
          message = error.response.data.message;
        } else if (typeof error.response.data.errors === "object") {
          message = error.response.data.errors;

          message.forEach((e: any) => {
            toastError(e.description);
          });

          return;
        } else {
          message = "Algo não deu certo, esse email existe?";
        }
      }

      toastError(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
}
