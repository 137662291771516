import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import { LoadingContext } from "../../context/loading/LoadingContext";
import getCustomerDataById from "./getCustomerDataById";
import useUpdateCustomerForm from "./useUpdateCustomerForm";
import * as masks from "../../utils/Mask";

interface ParamTypes {
  id: string;
}

const UpdateCustomer: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, errors, onSubmit, reset } =
    useUpdateCustomerForm();

  useEffect(() => {
    async function getCustomer() {
      showLoader();
      try {
        const data = await getCustomerDataById(id);
        reset(data);
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Atualizar cliente</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome Completo
                          </label>
                          <Input
                            type="text"
                            className={classnames({
                              "is-invalid": errors.name,
                            })}
                            id="name"
                            name="name"
                            placeholder="Nome completo"
                            innerRef={register({ required: true })}
                          />
                          {errors && errors.name && (
                            <FormFeedback>{errors.name.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.email,
                            })}
                          />
                          {errors && errors.email && (
                            <FormFeedback>{errors.email.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="birth_date"
                          >
                            Data de nascimento
                          </label>
                          <Input
                            type="date"
                            name="birth_date"
                            id="birth_date"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.birth_date,
                            })}
                          />
                          {errors && errors.birth_date && (
                            <FormFeedback>
                              {errors.birth_date.message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="document"
                          >
                            CPF/CNPJ
                          </label>
                          <Input
                            type="tel"
                            name="document"
                            id="document"
                            innerRef={register({ required: true })}
                            onChange={masks.cpfOrCnpjMask.onChange}
                            className={classnames({
                              "is-invalid": errors.document,
                            })}
                          />
                          {errors && errors.document && (
                            <FormFeedback>
                              {errors.document.message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mobile"
                          >
                            Celular
                          </label>
                          <Input
                            type="tel"
                            name="mobile"
                            id="mobile"
                            innerRef={register({ required: true })}
                            onChange={masks.phoneMask.onChange}
                            className={classnames({
                              "is-invalid": errors.mobile,
                            })}
                          />
                          {errors && errors.mobile && (
                            <FormFeedback>{errors.mobile.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço do Cliente
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zipcode"
                          >
                            CEP
                          </label>
                          <Input
                            type="tel"
                            name="zipcode"
                            id="zipcode"
                            innerRef={register({ required: true })}
                            onChange={masks.cepMask.onChange}
                            className={classnames({
                              "is-invalid": errors.zipcode,
                            })}
                          />
                          {errors && errors.zipcode && (
                            <FormFeedback>
                              {errors.zipcode.message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <Input
                            type="text"
                            name="street"
                            id="street"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.street,
                            })}
                          />
                          {errors && errors.street && (
                            <FormFeedback>{errors.street.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <Input
                            type="tel"
                            name="number"
                            id="number"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.number,
                            })}
                          />
                          {errors && errors.number && (
                            <FormFeedback>{errors.number.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <Input
                            type="text"
                            name="district"
                            id="district"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.district,
                            })}
                          />
                          {errors && errors.district && (
                            <FormFeedback>
                              {errors.district.message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.city,
                            })}
                          />
                          {errors && errors.city && (
                            <FormFeedback>{errors.city.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <Input
                            type="text"
                            name="state"
                            id="state"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.state,
                            })}
                          />
                          {errors && errors.state && (
                            <FormFeedback>{errors.state.message}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <Input
                            type="text"
                            name="complement"
                            id="complement"
                            innerRef={register({ required: true })}
                            className={classnames({
                              "is-invalid": errors.complement,
                            })}
                          />
                          {errors && errors.complement && (
                            <FormFeedback>
                              {errors.complement.message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> Criar cliente
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateCustomer;
