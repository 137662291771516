import { useCallback, useEffect, useState } from "react";

import { CreateEstablishmentFormData } from "../CreateEstablishment/CreateEstablishmentFormData";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";

export function useUpdateEstablishmentData(
  companyId: number,
  handleDefaultValues: (establishmentData: CreateEstablishmentFormData) => void
) {
  const [establishments, setEstablishments] =
    useState<CreateEstablishmentFormData>();

  const fetchEstablishment = useCallback(async (companyId) => {
    const response = await api.get(`companies/${companyId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    setEstablishments(response.data);
    return response.data;
  }, [setEstablishments]);

  useEffect(() => {
    fetchEstablishment(companyId).then(
      (establishmentData: CreateEstablishmentFormData) => {
        handleDefaultValues(establishmentData);
      }
    );
  }, [fetchEstablishment, companyId, handleDefaultValues]);

  return {
    establishments,
    fetchEstablishment,
  };
}
