import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import "./styles.scss";

interface IFinishPayment {
  stepper: any;
  finishPayment: {
    amount: number;
    installments: number;
    status: number;
    message: string;
  };
}

const FinishPayment: React.FC<IFinishPayment> = (props) => {
  const { stepper, finishPayment } = props;

  function formatInstallmentAmount(amount: number, installments: number) {
    if (amount > 0) {
      const formatAmount = amount / 100;
      const installmentAmount = formatAmount / installments;

      return installmentAmount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }

    return 0;
  }

  return (
    <Row className="py-3 d-flex align-items-center">
      <Col md={{ size: 8, offset: 2 }}>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          {finishPayment.status === 1 && (
            <>
              <div className="bg-primary text-white rounded-circle shadow-sm p-3 icon-check mb-2">
                <i className="fas fa-check m-0 p-0" />
              </div>
              <h1 className="text-primary text-center font-weight-800">
                Pagamento em Análise!
              </h1>
            </>
          )}

          {finishPayment.status === 3 && (
            <>
              <div className="bg-success text-white rounded-circle shadow-sm p-3 icon-check mb-2">
                <i className="fas fa-check m-0 p-0" />
              </div>
              <h1 className="text-success text-center font-weight-800">
                Pagamento Finalizado!
              </h1>
            </>
          )}

          {finishPayment.status === 7 && (
            <>
              <div className="bg-danger text-white rounded-circle shadow-sm p-3 icon-check mb-2">
                <i className="fas fa-times m-0 p-0"></i>
              </div>
              <h1 className="text-danger text-center font-weight-800">
                Pagamento Não Finalizado!
              </h1>
            </>
          )}

          {finishPayment.status === 1 ? (
            <p className="text-center font-weight-bold">
              Estamos validando o pagamento junto ao banco.
              <br />
              Aguarde uma resposta em breve.
            </p>
          ) : (
            <p className="text-center font-weight-bold">
              {finishPayment.message && finishPayment.message}
            </p>
          )}
        </div>
        <div className="info-buy">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <p className="font-weight-bold">Valor cobrado</p>
            <p className="font-weight-900">
              R${" "}
              {finishPayment.amount &&
                Number(finishPayment.amount / 100).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="font-weight-bold">Parcelado em</p>
            <p className="font-weight-900">
              {finishPayment.installments}x{" "}
              {finishPayment.amount &&
                formatInstallmentAmount(
                  finishPayment.amount,
                  finishPayment.installments
                )}
            </p>
          </div>
        </div>

        <hr className="my-2" />
        <div className="d-flex justify-content-between mt-3">
          <Button
            color="primary"
            className="btn-prev"
            onClick={() => stepper.previous()}
          >
            <i className="fas fa-arrow-left align-middle mr-sm-2 mr-0" />
            <span className="align-middle d-sm-inline-block d-none">
              Anterior
            </span>
          </Button>
          <Link className="btn-next btn btn-success" to="/admin/sales">
            <i className="fas fa-check mr-sm-2 mr-0" />
            <span className="align-middle d-sm-inline-block d-none">
              Concluido
            </span>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default FinishPayment;
