import React, { useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import * as masks from "../../utils/Mask";
import { useUpdateSellerForm } from "./useUpdateSellerForm";
import useGroups from "../../hooks/useGroups";

type ParamTypes = {
  id: string;
};

const UpdateSeller: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { groups, getGroups } = useGroups();
  const { register, onSubmit, errors, idgroup, setGroup } = useUpdateSellerForm(
    Number(id)
  );

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">Atualização do vendedor</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Insira os detalhes do vendedor.
                  </h6>
                  <FormGroup>
                    <Label className="form-label" for="idgroup">
                      Grupo econômico
                    </Label>

                    <select
                      name="idgroup"
                      id="idgroup"
                      className={classnames("form-control", {
                        "is-invalid": errors.idgroup,
                      })}
                      ref={register({ required: true })}
                      value={idgroup}
                      onChange={(e) => setGroup(e.target.value)}
                    >
                      <option value="">Selecione uma opção</option>
                      {groups.map((group) => (
                        <option key={group.idgroup} value={group.idgroup}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                    {errors && errors.idgroup && (
                      <FormFeedback>{errors.idgroup.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <Row>
                    <FormGroup tag={Col} lg="6">
                      <Label className="form-label" for="name">
                        Nome
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        innerRef={register({ required: true })}
                        className={classnames({ "is-invalid": errors.name })}
                      />
                      {errors && errors.name && (
                        <FormFeedback>{errors.name.message}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup tag={Col} lg="6">
                      <Label className="form-label" for="email">
                        E-mail
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        innerRef={register({ required: true })}
                        className={classnames({ "is-invalid": errors.email })}
                      />
                      {errors && errors.email && (
                        <FormFeedback>{errors.email.message}</FormFeedback>
                      )}
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup tag={Col} lg="6">
                      <Label className="form-label" for="mobile">
                        Celular
                      </Label>
                      <Input
                        type="tel"
                        name="mobile"
                        id="mobile"
                        placeholder="(99) 99999-9999"
                        onChange={masks.phoneMask.onChange}
                        innerRef={register({ required: true })}
                        className={classnames({ "is-invalid": errors.mobile })}
                      />
                      {errors && errors.mobile && (
                        <FormFeedback>{errors.mobile.message}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup tag={Col} lg="6">
                      <Label className="form-label" for="birth_date">
                        Data de nascimento
                      </Label>
                      <Input
                        type="date"
                        name="birth_date"
                        id="birth_date"
                        innerRef={register({ required: true })}
                        className={classnames({
                          "is-invalid": errors.birth_date,
                        })}
                      />
                      {errors && errors.birth_date && (
                        <FormFeedback>{errors.birth_date.message}</FormFeedback>
                      )}
                    </FormGroup>
                  </Row>

                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-check" /> Finalizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateSeller;
