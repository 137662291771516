/* eslint-disable import/no-anonymous-default-export */
export default (state: any, action: any) => {
  switch (action.type) {
    case "USER_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case "REGISTER_SUCCESS":
      return {
        ...state,
        success: action.payload,
      };
    case "LOGIN_SUCCESS":
      localStorage.setItem("@Paypi:token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case "REGISTER_FAIL":
    case "AUTH_ERROR":
    case "LOGIN_FAIL":
    case "LOGOUT":
      localStorage.removeItem("@Paypi:token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    case "CLEAR_SUCCESS":
      return {
        ...state,
        success: null,
      };
    default:
      return state;
  }
};
