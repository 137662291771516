import React from "react";
import { Row, Col } from "reactstrap";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="12">
          <div className="copyright text-center text-xl-left text-muted">
            <p className="m-0">
              © {new Date().getFullYear()}{" "}
              <span className="font-weight-bold ml-1" style={{color: '#5e72e4'}}>
                Powered by Ultragate - Paypi
              </span>
            </p>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
