import { useCallback, useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";

import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError } from "../../utils/toast";
import { UpdateGroupFormData } from "./UpdateGroupFormData";

export function useUpdateGroupData(
  groupId: number,
  handleDefaultValues: (groupData: UpdateGroupFormData) => void
) {
  const { showLoader, hideLoader } = useLoading();
  const [group, setGroup] = useState<UpdateGroupFormData>();

  const fetchGroup = useCallback(
    async (groupId) => {
      const response = await api.get(`groups/${groupId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setGroup(response.data);
      return response.data;
    },
    [setGroup]
  );

  useEffect(() => {
    showLoader();
    fetchGroup(groupId)
      .then((groupData: UpdateGroupFormData) => {
        hideLoader();
        handleDefaultValues(groupData);
      })
      .catch((error) => {
        hideLoader();
        let message = "";
        if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Algo deu errado ao buscar o Grupo Econômico";
        }
        toastError(message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGroup, groupId, handleDefaultValues]);

  return {
    group,
    fetchGroup,
  };
}
