/* eslint-disable react-hooks/exhaustive-deps */
import { formatCPF } from "@brazilian-utils/brazilian-utils";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Pagination from "react-responsive-pagination";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import { LoadingContext } from "../../context/loading/LoadingContext";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError } from "../../utils/toast";

interface ParamTypes {
  id: string;
}

interface Sale {
  idcontract: number;
  idpayment: number;
  amount: number;
  installments: number;
  created_at: string;
  status: string;
  statusCode: number;
  bg: string;
}

const LIMIT = 15;

const SalesCustomer: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [sales, setSales] = useState([]);
  const [pages, setPages] = useState(0);
  const [offset, setOffset] = useState(1);
  const [customer, setCustomer] = useState({
    name: "",
    document: "",
  });

  async function getSalesCustomer() {
    try {
      showLoader();
      const response = await api.get(
        `customers/${id}/sales?page=${offset}&limit=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      const totalPages = Math.ceil(response.data.totalItems / LIMIT);

      setPages(totalPages);

      setSales(response.data.sales);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError("Algo deu de errado ao buscar as vendas.");
    }
  }

  async function getCustomer() {
    try {
      const response = await api.get(`customers/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      let documentNumber = "";

      if (response.data.cpf_cnpj.length === 11) {
        documentNumber = formatCPF(response.data.cpf_cnpj);
      } else if (response.data.cpf_cnpj.length === 14) {
        documentNumber = formatCPF(response.data.cpf_cnpj);
      }

      setCustomer({
        name: response.data.name,
        document: documentNumber,
      });
    } catch (e) {
      console.log("Algo deu de errado ao buscar as vendas.");
    }
  }

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    document.title = "Vendas do Cliente | Paypi";
    getSalesCustomer();
  }, [offset]);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white">Vendas</h3>
                <p className="text-white mb-0">
                  Cliente: <strong>{customer.name}</strong> -{" "}
                  <strong>{customer.document}</strong>
                </p>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Nº</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Parcelas</th>
                    <th scope="col">Status</th>
                    <th scope="col">Última Atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((sale: Sale) => (
                    <tr key={sale.idcontract}>
                      <td>{sale.idcontract}</td>
                      <td>
                        {Number(sale.amount).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td>{sale.installments}</td>
                      <td>
                        <span
                          className={`${sale.bg} text-white p-1 rounded text-center d-block w-100`}
                        >
                          {sale.status}
                        </span>
                      </td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-yellow" />
                          {moment(sale.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={pages}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SalesCustomer;
