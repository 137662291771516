import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { useHistory } from "react-router-dom";

import { CreateEstablishmentFormData } from "./CreateEstablishmentFormData";
import * as masks from "../../utils/Mask";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";
import useLoading from "../../hooks/useLoading";

export function useCreateEstablishmentForm() {
  const history = useHistory();
  const { showLoader, hideLoader } = useLoading();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        company_name: yup.string().required("Nome Comercial é obrigatório"),
        social_reason: yup.string().required("Razão Social é obrigatório"),
        document_number: yup
          .string()
          .required("CPF/CNPJ é obrigatório")
          .transform(masks.cpfOrCnpjMask.transform)
          .test("validateCpfOrCnpj", "CPF/CNPJ inválido", (value) => {
            if (!value) {
              return false;
            }

            if (value.length === 11) {
              return isValidCPF(value);
            }

            if (value.length === 14) {
              return isValidCNPJ(value);
            }

            return false;
          }),
        idgroup: yup.string().required("Grupo econômico é obrigatório"),
        acquirers: yup
          .array()
          .of(
            yup.string().required("Adquirente é obrigatório").oneOf(["PHOEBUS"])
          )
          .min(1, "Adquirente é obrigatório"),
        access_token: yup
          .string()
          .required("Chave de Acesso da Phoebus é obrigatório"),
      }),
    []
  );

  const { register, handleSubmit, errors } =
    useForm<CreateEstablishmentFormData>({
      resolver: yupResolver(validationSchema),
    });

  const onSubmit = useCallback(
    async (formValues: CreateEstablishmentFormData) => {
      try {
        showLoader();

        await api.post("companies", JSON.stringify(formValues), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess("Estabelecimento criado com sucesso");

        history.push("/admin/establishments");
      } catch (error) {
        hideLoader();
        let message = "";

        if (error.response.data.errors) {
          error.response.data.errors.forEach((e: any) => {
            toastError(e.msg);
          });
          return;
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Algo deu errado ao cadastrar o Estabelecimento";
        }

        toastError(message);
      }
    },
    [history, showLoader, hideLoader]
  );

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
}
