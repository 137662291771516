// useRoutes.ts
import useAuth from "./useAuth";
import { adminUserRoutes, commonUserRoutes } from "../routes";
import { useCallback } from "react";

export type Route = {
  path: string;
  name: string;
  icon: string;
  component: React.FC<{}>;
  layout: string;
};

export function useRoutes() {
  const { user } = useAuth();

  const getRoutes = useCallback(() => {
    let routes: Route[] = [];

    if (user && user.role === "common") {
      routes = commonUserRoutes;
    } else {
      routes = adminUserRoutes;
    }

    return routes;
  }, [user]);

  return { getRoutes };
}
