import { parse, isDate } from "date-fns";

export const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;

export function parseDateString(value: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}
