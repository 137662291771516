import { useCallback, useContext } from "react";
import publicIp from "public-ip";
import api from "../../services/api";
import { toastError } from "../../utils/toast";
import { LoadingContext } from "../../context/loading/LoadingContext";
import { getToken } from "../../utils/Auth";

export default function useCreateSalesForm() {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const onSubmit = useCallback(
    async (formValues, stepper, setFinishPayment) => {
      const ipUser = await publicIp.v4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      });

      const [expirationMonth, expirationYear] =
        formValues.ccExpiration.split("/");

      let sessionID = "";

      const outputsessionid = document.querySelector(
        "#outputsessionid"
      ) as HTMLInputElement;

      if (outputsessionid) {
        sessionID = outputsessionid.value;
      }

      formValues.expirationMonth = expirationMonth;
      formValues.expirationYear = expirationYear;
      formValues.ip = ipUser;
      formValues.sessionID = sessionID;

      showLoader();
      try {
        const response = await api.post(
          "sales",
          JSON.stringify({
            ...formValues,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        hideLoader();

        setFinishPayment({
          amount: response.data.amount,
          installments: response.data.installments,
          status: response.data.status,
          message: response.data.message,
        });

        stepper.next();
      } catch (error) {
        hideLoader();
        let message = null;

        message =
          "Não foi possível finalizar seu pagamento, por favor tente novamente mais tarde";

        if (error.response) {
          if (error.response.data.message) {
            message = error.response.data.message;
          } else if (typeof error.response.data.errors === "object") {
            message = error.response.data.errors;

            message.forEach((e: any) => {
              toastError(e.description);
            });

            return;
          } else if (typeof error.response.data.errors !== "object") {
            message = error.response.data.errors;
          } else {
            message =
              "Não foi possível finalizar seu pagamento, por favor tente novamente mais tarde";
          }
        }

        toastError(message);
      }
    },
    [showLoader, hideLoader]
  );

  return {
    onSubmit,
  };
}
