import React, { useContext } from "react";
import {
  Route,
  Redirect,
  RouteProps as ReactDOMRouteProps,
} from "react-router-dom";

import { AuthContext } from "../context/auth/AuthContext";

import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: any;
}

const PrivateRoute: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { token } = useContext(AuthContext);

  const Layout = token ? AdminLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) =>
        isPrivate === !!token ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/login" : "/admin/sales",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
