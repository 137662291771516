import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import AdminFooter from "../components/Footers/AdminFooter";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import { AuthContext } from "../context/auth/AuthContext";
import { Route, useRoutes } from "../hooks/useRoutes";

const Admin = (props: any) => {
  const [routes, setRoutes] = useState<Route[]>([]);
  const { getRoutes } = useRoutes();
  const { user, loadUser } = useContext(AuthContext);

  const mainContent = React.useRef(null);
  const location = useLocation();

  const getAllRoutes = useCallback(() => {
    const routes = getRoutes();
    setRoutes(routes);
  }, [getRoutes]);

  React.useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getAllRoutes();
  }, [user, getAllRoutes]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [location]);

  const getBrandText = (path: any) => {
    for (let i = 0; i < routes.length; i++) {
      if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/sales",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props.children}
          brandText={getBrandText(props.children.props.location.pathname)}
        />
        {props.children}
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
