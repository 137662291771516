import { useCallback, useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";

import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError } from "../../utils/toast";
import { UpdateSellerFormData } from "./UpdateSellerFormData";

export function useUpdateSellerData(
  sellerId: number,
  handleDefaultValues: (sellerData: UpdateSellerFormData) => void
) {
  const { showLoader, hideLoader } = useLoading();
  const [seller, setSeller] = useState<UpdateSellerFormData>();

  const fetchSeller = useCallback(
    async (sellerId) => {
      const response = await api.get(`sellers/${sellerId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setSeller(response.data);
      return response.data;
    },
    [setSeller]
  );

  useEffect(() => {
    showLoader();
    fetchSeller(sellerId)
      .then((sellerData: UpdateSellerFormData) => {
        hideLoader();
        handleDefaultValues(sellerData);
      })
      .catch((error) => {
        hideLoader();
        let message = "";
        if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Algo deu errado ao buscar o Vendedor";
        }
        toastError(message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSeller, sellerId, handleDefaultValues]);

  return {
    seller,
    fetchSeller,
  };
}
