import { formatCEP, formatCNPJ, formatCPF } from "@brazilian-utils/brazilian-utils";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";

export default async function getCustomerDataById(id: string) {
  let customer = {};

  const { data } = await api.get(`customers/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  let document: string | null ="";

  if (data.cpf_cnpj.length === 11) {
    document = formatCPF(data.cpf_cnpj)
  } else if (data.cpf_cnpj.length === 14) {
    document = formatCNPJ(data.cpf_cnpj)
  }

  customer = {
    document,
    name: data.name,
    email: data.email,
    mobile: data.mobile,
    birth_date: data.birth_date,
    zipcode: formatCEP(data.address[0].zipcode),
    street: data.address[0].street,
    number: data.address[0].number,
    district: data.address[0].district,
    city: data.address[0].city,
    state: data.address[0].state,
    complement: data.address[0].complement || "",
  };

  return customer;
}
