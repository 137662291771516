import React, { FormEvent, useContext, useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// core components
import UserHeader from "../../components/Headers/UserHeader";
import { AuthContext } from "../../context/auth/AuthContext";
import { LoadingContext } from "../../context/loading/LoadingContext";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import * as masks from "../../utils/Mask";
import { toastError, toastSuccess } from "../../utils/toast";

const Profile: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    document.title = "Perfil | Paypi";
  }, []);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setBirthDate(user.birth_date);
      setMobile(user.mobile);
    }
  }, [user]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    showLoader();

    try {
      await api.put(
        "users",
        JSON.stringify({ name, mobile, birth_date: birthDate }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      hideLoader();

      toastSuccess("Dados atualizado com sucesso.");
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e: any) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          "Ops, não foi possível atualizar seus dados, por favor tente novamente";
      }
      toastError(message);
    }
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Minha conta</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome Completo
                          </label>
                          <Input
                            type="text"
                            className="form-control-alternative"
                            id="name"
                            name="name"
                            placeholder="Nome completo"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <Input
                            type="email"
                            className="form-control-alternative"
                            id="email"
                            placeholder="example@example.com"
                            name="email"
                            value={email}
                            disabled
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="birth_date"
                          >
                            Data de nascimento
                          </label>
                          <Input
                            type="date"
                            className="form-control-alternative"
                            id="birth_date"
                            name="birth_date"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mobile"
                          >
                            Celular
                          </label>
                          <Input
                            type="tel"
                            className="form-control form-control-alternative"
                            id="mobile"
                            name="mobile"
                            value={mobile}
                            onChange={(e) => {
                              setMobile(e.target.value);
                              masks.phoneMask.onChange(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <Button type="submit" className="btn btn-success px-4">
                        <i className="fas fa-paper-plane" /> Enviar
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
