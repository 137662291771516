import { createContext, useReducer } from "react";

import AuthReducer from "./AuthReducer";
import api from "../../services/api";

interface ILogin {
  email: string;
  password: string;
}

interface IRegister {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  birth_date: string;
  mobile: string;
}

interface IError {
  msg: string;
}

interface IAuthContext {
  token: string;
  isAuthenticated: string;
  loading: boolean;
  user: {
    name: string;
    email: string;
    birth_date: string;
    mobile: string;
    role: string;
  };
  error: string | Array<IError>;
  success: string;
  login: (credentials: ILogin) => Promise<void>;
  logout: () => void;
  registerUser: (credentials: IRegister) => Promise<void>;
  clearErrors: () => void;
  clearSuccess: () => void;
  loadUser: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: React.FC = ({ children }) => {
  const initialState = {
    token: localStorage.getItem("@Paypi:token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
    success: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  async function loadUser() {
    let token = "";
    if (localStorage.getItem("@Paypi:token")) {
      token = localStorage.getItem("@Paypi:token") as string;
    }

    try {
      const res = await api.get("auth", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: "USER_LOADED", payload: res.data });
    } catch (error) {
      dispatch({ type: "AUTH_ERROR" });
    }
  }

  async function login({ email, password }: ILogin): Promise<void> {
    try {
      const response = await api.post(
        "login",
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          token: response.data.token,
        },
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          "Ops, tivemos um problema em efetuar seu login, por favor tente novamente !";
      }

      dispatch({
        type: "LOGIN_FAIL",
        payload: message,
      });
    }
  }

  async function registerUser({
    name,
    email,
    password,
    password_confirmation,
    birth_date,
    mobile,
  }: IRegister): Promise<void> {
    try {
      await api.post(
        "users",
        {
          name,
          email,
          password,
          password_confirmation,
          birth_date: birth_date,
          mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: "REGISTER_SUCCESS",
        payload:
          "Cadastro realizado com sucesso, mas você precisa ser aprovada para poder entrar!",
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          "Ops, tivemos um problema em finalizar seu cadastro, por favor tente novamente !";
      }

      dispatch({
        type: "LOGIN_FAIL",
        payload: message,
      });
    }
  }

  function logout() {
    dispatch({ type: "LOGOUT" });
  }

  function clearErrors() {
    dispatch({ type: "CLEAR_ERRORS" });
  }

  function clearSuccess() {
    dispatch({ type: "CLEAR_SUCCESS" });
  }

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        success: state.success,
        login,
        logout,
        registerUser,
        clearErrors,
        clearSuccess,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
