import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { formatCPF, formatCNPJ } from "@brazilian-utils/brazilian-utils";
import {
  Card,
  CardHeader,
  Container,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  CardFooter,
} from "reactstrap";
import Pagination from "react-responsive-pagination";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import useLoading from "../../hooks/useLoading";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError } from "../../utils/toast";
import useAuth from "../../hooks/useAuth";

type IEstablishment = {
  idcompany: number;
  company_name: string;
  document_number: string;
  active: string;
  bg_status: string;
  status: string;
  group: {
    name: string;
  };
};

const LIMIT = 15;

export const Establishments: React.FC = () => {
  const { user } = useAuth();
  const { showLoader, hideLoader } = useLoading();
  const [companies, setCompanies] = useState<IEstablishment[]>([]);
  const [pages, setPages] = useState(0);
  const [offset, setOffset] = useState(1);

  const getEstablishments = useCallback(async () => {
    try {
      showLoader();
      const response = await api.get(
        `companies?page=${offset}&limit=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      let companies: IEstablishment[] = [];

      const totalPages = Math.ceil(response.data.totalItems / LIMIT);

      setPages(totalPages);

      if (response.data.companies.length) {
        companies = response.data.companies.map((company: IEstablishment) => {
          let document_number: string = "";
          company.bg_status = "bg-success";
          company.status = "Ativo";

          if (company.document_number.length === 11) {
            document_number = formatCPF(company.document_number);
          } else if (company.document_number.length === 14) {
            document_number = formatCNPJ(company.document_number);
          }

          if (company.active === "W") {
            company.bg_status = "bg-warning";
            company.status = "Aguardando";
          } else if (company.active === "D") {
            company.bg_status = "bg-danger";
            company.status = "Desativado";
          }

          return {
            ...company,
            document_number,
          };
        });
      }

      setCompanies(companies);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError("Algo deu errado ao buscar os Estabelecimentos");
    }
  }, [hideLoader, showLoader, offset]);

  useEffect(() => {
    document.title = "Estabelecimentos | Paypi";
    getEstablishments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [offset]);

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <div className="text-right">
              {user && user.role !== "common" && (
                <Link
                  className="btn btn-default mb-3 mt-2"
                  to="/admin/establishments/create"
                >
                  <i className="fas fa-plus" /> Criar estabelecimento
                </Link>
              )}
            </div>

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Estabelecimentos</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col" className="text-white">
                      Empresa
                    </th>
                    <th scope="col" className="text-white">
                      CPF/CNPJ
                    </th>
                    <th scope="col" className="text-white">
                      Grupo
                    </th>
                    <th scope="col" className="text-white">
                      Status
                    </th>
                    <th scope="col" className="text-white">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((establishment) => (
                    <tr key={establishment.idcompany}>
                      <td>{establishment.company_name}</td>
                      <td>{establishment.document_number}</td>
                      <td>{establishment.group.name}</td>
                      <td>
                        <span
                          className={`${establishment.bg_status} text-white p-1 px-3 rounded text-center`}
                        >
                          {establishment.status}
                        </span>
                      </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {user && user.role !== "common" ? (
                              <>
                                <Link
                                  to={`/admin/establishments/${establishment.idcompany}`}
                                  className="dropdown-item"
                                  tabIndex={0}
                                  role="menuitem"
                                >
                                  <span>
                                    <i className="fas fa-edit" /> Editar
                                  </span>
                                </Link>
                                <Link
                                  to={`/admin/establishments/${establishment.idcompany}/users`}
                                  className="dropdown-item"
                                  tabIndex={0}
                                  role="menuitem"
                                >
                                  <span>
                                    <i className="fas fa-users" /> Vendedores
                                  </span>
                                </Link>
                              </>
                            ) : (
                              <>
                                <DropdownItem disabled>
                                  <span>
                                    <i className="fas fa-edit" /> Editar
                                  </span>
                                </DropdownItem>
                                <DropdownItem disabled>
                                  <span>
                                    <i className="fas fa-users" /> Vendedores
                                  </span>
                                </DropdownItem>
                              </>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={pages}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
