import { useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";

type IUsers = {
  iduser: number;
  name: string;
  linked: boolean;
};

type SelectItem = {
  id: number;
  selected: boolean;
};

interface FormData {
  selected: SelectItem[] | undefined;
}

type FormProperties = {
  data: FormData;
};

export function useCreateEstablishmentUsersForm(
  users: IUsers[],
  companyId: number
) {
  const history = useHistory();
  const { showLoader, hideLoader } = useLoading();

  const { register, handleSubmit, errors, reset, control } =
    useForm<FormProperties>();

  const handleDefaultValues = useCallback(
    (users: IUsers[]) => {
      const selected: SelectItem[] = [];

      users.forEach((user) => {
        selected.push({ id: user.iduser, selected: user.linked });
      });

      const defaultValues: FormProperties = {
        data: {
          selected,
        },
      };
      reset(defaultValues);
    },
    [reset]
  );

  useEffect(() => {
    if (users.length > 0) {
      handleDefaultValues(users);
    }
  }, [users, handleDefaultValues]);

  const onSubmit = useCallback(
    async (data: FormProperties) => {
      try {
        showLoader();

        await api.post(
          `companies/${companyId}/users`,
          JSON.stringify(data.data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        hideLoader();

        toastSuccess("Vendedores do Estabelecimento cadastro com sucesso");

        history.push("/admin/establishments");
      } catch (error) {
        hideLoader();
        let message = "";

        if (error.response.data.errors) {
          error.response.data.errors.forEach((e: any) => {
            toastError(e.msg);
          });
          return;
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message =
            "Algo deu errado ao cadastrar os Vendedores do Estabelecimento";
        }

        toastError(message);
      }
    },
    [history, companyId, showLoader, hideLoader]
  );

  return {
    register,
    errors,
    control,
    Controller,
    onSubmit: handleSubmit(onSubmit),
  };
}
