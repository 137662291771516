import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Loading from "./components/Loading";
import AppProvider from "./context";
import CreateCustomer from "./pages/CreateCustomer";
import { CreateEstablishment } from "./pages/CreateEstablishment";
import { CreateSeller } from "./pages/CreateSeller";
import { CreateGroup } from "./pages/CreateGroup";
import CreateSales from "./pages/CreateSales/index";
import Customers from "./pages/Customers";
import { Establishments } from "./pages/Establishments";
import Forgot from "./pages/Forgot";
import { Groups } from "./pages/Groups";
import ResetPassword from "./pages/ResetPassword";
import Sales from "./pages/Sales";
import SalesCustomer from "./pages/SalesCustomer";
import { Sellers } from "./pages/Sellers";
import UpdateCustomer from "./pages/UpdateCustomer";
import Users from "./pages/Users";

import PrivateRoute from "./routes/PrivateRoute";
import Login from "./views/examples/Login";
import Profile from "./views/examples/Profile";
import Register from "./views/examples/Register";
import Index from "./views/Index";
import { UpdateEstablishment } from "./pages/UpdateEstablishment";
import UpdateGroup from "./pages/UpdateGroup";
import UpdateSeller from "./pages/UpdateSeller";
import CreateEstablishmentUsers from "./pages/CreateEstablishmentUsers";

const App: React.FC = () => {
  return (
    <AppProvider>
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/login"
            component={Login}
            isPrivate={false}
          />
          <PrivateRoute
            exact
            path="/register"
            component={Register}
            isPrivate={false}
          />
          <PrivateRoute
            exact
            path="/forgot"
            component={Forgot}
            isPrivate={false}
          />
          <PrivateRoute
            exact
            path="/reset"
            component={ResetPassword}
            isPrivate={false}
          />
          <PrivateRoute path="/admin/index" component={Index} isPrivate />
          <PrivateRoute exact path="/admin/sales" component={Sales} isPrivate />
          <PrivateRoute
            exact
            path="/admin/establishments"
            component={Establishments}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/establishments/create"
            component={CreateEstablishment}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/establishments/:id"
            component={UpdateEstablishment}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/establishments/:id/users"
            component={CreateEstablishmentUsers}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/sellers"
            component={Sellers}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/sellers/create"
            component={CreateSeller}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/sellers/:id"
            component={UpdateSeller}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/groups"
            component={Groups}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/groups/create"
            component={CreateGroup}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/groups/:id"
            component={UpdateGroup}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/customers"
            component={Customers}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/customers/create"
            component={CreateCustomer}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/customers/:id"
            component={UpdateCustomer}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/customers/:id/sales"
            component={SalesCustomer}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/profile"
            component={Profile}
            isPrivate
          />
          <PrivateRoute exact path="/admin/users" component={Users} isPrivate />
          <PrivateRoute
            exact
            path="/admin/sales/create"
            component={CreateSales}
            isPrivate
          />
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
      <Loading />
    </AppProvider>
  );
};

export default App;
