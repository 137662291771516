import { useState } from "react";
import api from "../services/api";
import { getToken } from "../utils/Auth";

type Companies = {
  idcompany: number;
  company_name: string;
};

export default function useCompanies() {
  const [companies, setCompanies] = useState<Companies[]>([]);

  async function getCompanies() {
    try {
      const response = await api.get("companies/all", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setCompanies(response.data);
    } catch (error) {}
  }

  return { companies, getCompanies };
}
