import { useMemo } from "react";
import { useForm } from "react-hook-form";
import PaymentDataFormData from "./PaymentDataFormData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as masks from "../../../../utils/Mask";
import { parseCurrency } from "@brazilian-utils/brazilian-utils";

export default function useCheckoutForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        customerName: yup
          .string()
          .required("Nome é obrigatório")
          .matches(
            /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|\s]+$/,
            "Nome só pode conter letras"
          ),
        cardHolder: yup
          .string()
          .required("Nome impresso no cartão é obrigatório")
          .matches(
            /^[a-zA-Z][a-zA-Z|\s]+$/,
            "Nome do portador do cartão só pode conter letras"
          )
          .max(26, "Nome impresso no cartão deve ter no máximo 26 caracteres"),
        cardNumber: yup
          .string()
          .required("Número do cartão é obrigatório")
          .max(19, "Número do cartão deve ter no máximo 19 números")
          .matches(/^[0-9]+$/, "Número do cartão só pode ter números"),
        ccExpiration: yup.string().required("Data de expiração é obrigatório"),
        securityCode: yup
          .string()
          .required("CVV é obrigatório")
          .matches(/^[0-9]+$/, "CVV só pode ter números"),
        amount: yup
          .string()
          .required("Valor é obrigatório")
          .transform(masks.phoneMask.transform)
          .test("validateAmout", "Valor mínimo é de R$ 10,00", (value) => {
            if (!value) {
              return true;
            }
            return parseCurrency(value) > 9.99;
          }),
        idcompany: yup.string().required("Estabelecimento é obrigatório"),
        installments: yup.string().required("Parcelamento é obrigatório"),
        brand: yup.string().required("Bandeira é obrigatório"),
        gateway_name: yup.string().required("Adquirente é obrigatório"),
      }),
    []
  );

  const { register, handleSubmit, errors, trigger } =
    useForm<PaymentDataFormData>({
      resolver: yupResolver(validationSchema),
    });

  return {
    register,
    errors,
    handleSubmit,
    trigger,
  };
}
