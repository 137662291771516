import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  CustomInput,
} from "reactstrap";
import { useParams } from "react-router-dom";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import useGroups from "../../hooks/useGroups";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { useCreateEstablishmentUsersForm } from "./useCreateEstablishmentUsersForm";

type ParamTypes = {
  id: string;
};

type IUsers = {
  iduser: number;
  name: string;
  linked: boolean;
};

const CreateEstablishmentUsers: React.FC = () => {
  const [users, setUsers] = useState<IUsers[]>([]);
  const [establishment, setEstablishment] = useState({
    company_name: "",
    document_number: "",
    social_reason: "",
    idgroup: 0,
  });
  const { groups, getGroups } = useGroups();
  const { id } = useParams<ParamTypes>();

  const { onSubmit, control, Controller } =
    useCreateEstablishmentUsersForm(users, Number(id));

  async function getEstablishment() {
    try {
      const { data } = await api.get(`companies/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setEstablishment({
        company_name: data.company_name,
        document_number: data.document_number,
        social_reason: data.social_reason,
        idgroup: data.idgroup,
      });
    } catch (error) {}
  }

  async function getUsers() {
    try {
      const { data } = await api.get(
        `companies/${id}/group/${establishment.idgroup}/users`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      setUsers(data);
    } catch (error) {}
  }

  useEffect(() => {
    getGroups();
    getEstablishment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (establishment.idgroup) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishment.idgroup]);

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">Vendedores do Estabelecimento</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <FormGroup>
                    <Label className="form-label" for="company_name">
                      Nome Comercial
                    </Label>
                    <Input
                      type="text"
                      name="company_name"
                      id="company_name"
                      value={establishment.company_name}
                      onChange={(e) =>
                        setEstablishment({
                          ...establishment,
                          company_name: e.target.value,
                        })
                      }
                      disabled
                    />
                  </FormGroup>
                  <Row>
                    <FormGroup tag={Col} lg="4">
                      <Label className="form-label" for="document_number">
                        CPF/CNPJ
                      </Label>
                      <Input
                        type="tel"
                        name="document_number"
                        id="document_number"
                        value={establishment.document_number}
                        onChange={(e) =>
                          setEstablishment({
                            ...establishment,
                            document_number: e.target.value,
                          })
                        }
                        disabled
                      />
                    </FormGroup>
                    <FormGroup tag={Col} lg="8">
                      <Label className="form-label" for="social_reason">
                        Razão Social
                      </Label>
                      <Input
                        type="text"
                        name="social_reason"
                        id="social_reason"
                        value={establishment.social_reason}
                        onChange={(e) =>
                          setEstablishment({
                            ...establishment,
                            social_reason: e.target.value,
                          })
                        }
                        disabled
                      />
                    </FormGroup>
                  </Row>
                  <FormGroup>
                    <Label className="form-label" for="idgroup">
                      Grupo econômico
                    </Label>
                    <Input
                      type="select"
                      name="idgroup"
                      id="idgroup"
                      disabled
                      value={establishment.idgroup}
                      onChange={(e) =>
                        setEstablishment({
                          ...establishment,
                          idgroup: Number(e.target.value),
                        })
                      }
                    >
                      <option value="">Selecione uma opção</option>
                      {groups.map((group) => (
                        <option key={group.idgroup} value={group.idgroup}>
                          {group.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Table className="align-items-center" responsive bordered>
                      <thead>
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Vinculado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={user.iduser}>
                            <td>{user.name}</td>
                            <td>
                              <Controller
                                as={<input />}
                                control={control}
                                type="hidden"
                                name={`data.selected[${index}].id`}
                                defaultValue={user.iduser}
                              />
                              <Controller
                                render={(props) => (
                                  <CustomInput
                                    type="checkbox"
                                    label={
                                      user.linked
                                        ? "Vinculado"
                                        : "Não Vinculado"
                                    }
                                    id={props.name}
                                    name={props.name}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                  />
                                )}
                                control={control}
                                id={`data.selected[${index}].id`}
                                name={`data.selected[${index}].selected`}
                                defaultValue={user.linked}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroup>

                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-check" /> Finalizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateEstablishmentUsers;
