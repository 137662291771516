import React from "react";

import { AuthContextProvider } from "./auth/AuthContext";
import { LoadingContextProvider } from "./loading/LoadingContext";

// eslint-disable-next-line react/prop-types
const AppProvider: React.FC = ({ children }) => (
  <LoadingContextProvider>
    <AuthContextProvider>{children}</AuthContextProvider>
  </LoadingContextProvider>
);

export default AppProvider;
