import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useForgotForm } from "./useForgotForm";

const Forgot: React.FC = () => {
  const { register, onSubmit, errors } = useForgotForm();

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Recuperar senha</small>
            </div>
            <Form role="form" onSubmit={onSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoFocus
                    placeholder="Digite seu email"
                    type="email"
                    autoComplete="new-email"
                    id="email"
                    name="email"
                    className={classnames({
                      "is-invalid": errors.email,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" block>
                  Recuperar
                </Button>
              </div>
            </Form>

            <p className="text-center m-0" style={{ fontSize: "14px" }}>
              <Link to="/login">
                <span className="d-flex align-items-center justify-content-center">
                  <i
                    className="fas fa-arrow-left"
                    style={{ fontSize: "12px", marginRight: '4px' }}
                  />{" "}
                  Volte ao login
                </span>
              </Link>
            </p>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Forgot;
