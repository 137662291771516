import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Row,
  Col,
  FormFeedback,
  CustomInput,
} from "reactstrap";
import PaymentDataFormData from "./PaymentDataFormData";
import usePaymentDataForm from "./usePaymentDataForm";
import { isObjEmpty } from "../../../../utils/Utils";
import * as masks from "../../../../utils/Mask";
import useCompanies from "../../../../hooks/useCompanies";
import api from "../../../../services/api";
import { getToken } from "../../../../utils/Auth";

interface IPaymentData {
  stepper: any;
  paymentData: (data: any) => void;
  submitData: (data: any) => void;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;
const max = 100000000;

const PaymentData: React.FC<IPaymentData> = (props) => {
  const { companies, getCompanies } = useCompanies();
  const [acquirers, setAcquirers] = useState<Array<string>>(["phoebus"]);
  const [value, setValue] = useState(0);

  const { paymentData, submitData } = props;

  const { register, errors, handleSubmit, trigger } = usePaymentDataForm();

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error(`invalid value property`);
  }

  const onValueChange = (value: number) => {
    setValue(value);
  };

  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(nextValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [max, onValueChange, value]
  );

  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = (value / 100).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const onSubmit = (data: PaymentDataFormData) => {
    trigger();
    if (isObjEmpty(errors)) {
      paymentData(data);
      submitData(data);
    }
  };

  const handleOnChangeCompany = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value) {
        try {
          const response = await api.get(`companies/${event.target.value}`, {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          });

          const acquirers = response.data.acquirers;

          if (acquirers) {
            const listAcquirers = acquirers.toLowerCase().split(";");
            setAcquirers(listAcquirers);
          } else {
            setAcquirers(["phoebus"]);
          }
        } catch (error) {
          console.log("Algo deu de errado");
        }
      }
    },
    [setAcquirers]
  );

  return (
    <>
      <div className="content-header">
        <h5 className="mb-0">Pagamento</h5>
        <small className="text-muted">
          Insira os dados para finalizar o seu pagamento.
        </small>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-block my-3">
          <CustomInput
            type="radio"
            id="credit"
            label="Cartão de Crédito"
            defaultChecked
          />
        </div>

        <FormGroup>
          <Label className="form-label" for="idcompany">
            Estabelecimento
          </Label>
          <select
            name="idcompany"
            id="idcompany"
            ref={register({ required: true })}
            className={`form-control ${classnames({
              "is-invalid": errors.idcompany,
            })}`}
            onChange={handleOnChangeCompany}
          >
            <option value="">Selecione uma opção</option>
            {companies.map((company) => (
              <option key={company.idcompany} value={company.idcompany}>
                {company.company_name}
              </option>
            ))}
          </select>
          {errors && errors.idcompany && (
            <FormFeedback>{errors.idcompany.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label className="form-label" for="customerName">
            Nome completo do portador do cartão
          </Label>
          <Input
            type="text"
            name="customerName"
            id="customerName"
            innerRef={register({ required: true })}
            className={classnames({
              "is-invalid": errors.customerName,
            })}
          />
          {errors && errors.customerName && (
            <FormFeedback>{errors.customerName.message}</FormFeedback>
          )}
        </FormGroup>
        <Row>
          <FormGroup tag={Col} lg="6">
            <Label className="form-label" for="cardHolder">
              Nome impresso no cartão
            </Label>
            <Input
              type="text"
              name="cardHolder"
              id="cardHolder"
              innerRef={register({ required: true })}
              className={classnames({
                "is-invalid": errors.cardHolder,
              })}
            />
            {errors && errors.cardHolder && (
              <FormFeedback>{errors.cardHolder.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup tag={Col} lg="6">
            <Label className="form-label" for="cardNumber">
              Número do cartão
            </Label>
            <Input
              type="text"
              name="cardNumber"
              id="cardNumber"
              innerRef={register({ required: true })}
              className={classnames({
                "is-invalid": errors.cardNumber,
              })}
              maxLength={19}
            />
            {errors && errors.cardNumber && (
              <FormFeedback>{errors.cardNumber.message}</FormFeedback>
            )}
          </FormGroup>
        </Row>
        <Row>
          <FormGroup tag={Col} md="6" lg="3">
            <Label className="form-label" for="ccExpiration">
              Data de expiração
            </Label>
            <Input
              type="text"
              name="ccExpiration"
              id="ccExpiration"
              innerRef={register({ required: true })}
              className={classnames({
                "is-invalid": errors.ccExpiration,
              })}
              onChange={masks.expirationMask.onChange}
            />
            {errors && errors.ccExpiration && (
              <FormFeedback>{errors.ccExpiration.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup tag={Col} md="6" lg="3">
            <Label className="form-label" for="securityCode">
              CVV
            </Label>
            <Input
              type="text"
              name="securityCode"
              id="securityCode"
              innerRef={register({ required: true })}
              className={classnames({
                "is-invalid": errors.securityCode,
              })}
              maxLength={4}
            />
            {errors && errors.securityCode && (
              <FormFeedback>{errors.securityCode.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup tag={Col} md="12" lg="6">
            <Label className="form-label" for="brand">
              Bandeira
            </Label>
            <select
              name="brand"
              id="brand"
              ref={register({ required: true })}
              className={`form-control ${classnames({
                "is-invalid": errors.brand,
              })}`}
            >
              <option value="">Selecione uma opção</option>
              <option value="3">Visa</option>
              <option value="1">Mastercard</option>
              <option value="5">Elo</option>
              <option value="7">Hipercard</option>
              <option value="8">American Express</option>
            </select>
            {errors && errors.brand && (
              <FormFeedback>{errors.brand.message}</FormFeedback>
            )}
          </FormGroup>
        </Row>
        <Row>
          <FormGroup tag={Col} lg="4">
            <Label className="form-label" for="amount">
              Valor
            </Label>
            <Input
              type="text"
              name="amount"
              id="amount"
              innerRef={register({ required: true })}
              className={classnames({
                "is-invalid": errors.amount,
              })}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={valueDisplay}
            />
            {errors && errors.amount && (
              <FormFeedback>{errors.amount.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup tag={Col} lg="4">
            <Label className="form-label" for="installments">
              Parcelamento
            </Label>
            <select
              name="installments"
              id="installments"
              ref={register({ required: true })}
              className={`form-control ${classnames({
                "is-invalid": errors.installments,
              })}`}
            >
              <option value="">Selecione uma opção</option>
              <option value="1">1x</option>
              <option value="2">2x</option>
              <option value="3">3x</option>
              <option value="4">4x</option>
              <option value="5">5x</option>
              <option value="6">6x</option>
              <option value="7">7x</option>
              <option value="8">8x</option>
              <option value="9">9x</option>
              <option value="10">10x</option>
              <option value="11">11x</option>
              <option value="12">12x</option>
            </select>
            {errors && errors.installments && (
              <FormFeedback>{errors.installments.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup tag={Col} lg="4">
            <Label className="form-label" for="gateway_name">
              Adquirente
            </Label>
            <select
              name="gateway_name"
              id="gateway_name"
              ref={register({ required: true })}
              className={`form-control ${classnames({
                "is-invalid": errors.gateway_name,
              })}`}
            >
              {acquirers.length > 1 && (
                <option value="" selected>
                  Selecione uma opção
                </option>
              )}
              {acquirers.length > 0 && (
                <>
                  {acquirers.map((acquirer) => (
                    <option key={acquirer} value={acquirer}>
                      {acquirer.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
            {errors && errors.gateway_name && (
              <FormFeedback>{errors.gateway_name.message}</FormFeedback>
            )}
          </FormGroup>
        </Row>
        <hr className="my-2" />
        <div className="d-flex justify-content-end align-items-center">
          <Button type="submit" color="success" className="btn-next d-flex justify-content-center align-items-center">
            <i className="fas fa-check mr-sm-2 mr-0" />
            <span className="align-middle d-sm-inline-block d-none">
              Processar a compra
            </span>
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PaymentData;
