import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import * as masks from "../../utils/Mask";
import { isValidPhone } from "@brazilian-utils/brazilian-utils";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { AuthContext } from "../../context/auth/AuthContext";
import { LoadingContext } from "../../context/loading/LoadingContext";
import { useHistory } from "react-router-dom";
import { toastError, toastSuccess } from "../../utils/toast";

interface RegisterFormData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  birth_date: string;
  mobile: string;
}

const Register: React.FC = () => {
  const history = useHistory();
  const { registerUser, error, clearErrors, clearSuccess, success } =
    useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const RegisterSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Nome é obrigatório"),
        email: yup
          .string()
          .required("Email é obrigatório")
          .email("Por favor inclua um email válido"),
        password: yup
          .string()
          .required("Por favor, insira sua senha")
          .matches(
            /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "A senha deve conter pelo menos 6 caracteres, uma maiúscula, um número e um caractere especial"
          ),
        password_confirmation: yup
          .string()
          .required("Por favor, confirme sua senha")
          .when("password", {
            is: (password: string) => !!(password && password.length > 0),
            then: yup
              .string()
              .oneOf([yup.ref("password")], "Senha não coincide"),
          }),
        birth_date: yup.string().required("Data de nascimento é obrigatório"),
        mobile: yup
          .string()
          .required("Celular é obrigatório")
          .transform(masks.phoneMask.transform)
          .test("validatePhone", "Celular inválido", (value) => {
            if (!value) {
              return false;
            }

            return isValidPhone(value);
          }),
      }),
    []
  );

  const { register, handleSubmit, errors } = useForm<RegisterFormData>({
    resolver: yupResolver(RegisterSchema),
  });

  useEffect(() => {
    document.title = "Cadastro | Paypi";
  }, []);

  useEffect(() => {
    if (success) {
      hideLoader();
      toastSuccess(success);
      clearSuccess();
      history.push("/login");
    }

    if (error) {
      hideLoader();
      if (typeof error === "object") {
        error.forEach((e) => {
          toastError(e.msg);
        });
      } else {
        toastError(error);
      }

      clearErrors();
    }
  }, [history, error, success, clearSuccess, clearErrors, hideLoader]);

  const onSubmit = useCallback(
    (data) => {
      showLoader();
      registerUser(data);
    },
    [registerUser, showLoader]
  );

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Crie sua conta</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoFocus
                    placeholder="Digite seu nome"
                    type="text"
                    id="name"
                    name="name"
                    className={classnames({
                      "is-invalid": errors.name,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.name && (
                  <small className="text-danger">{errors.name.message}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Digite seu email"
                    type="email"
                    autoComplete="new-email"
                    id="email"
                    name="email"
                    className={classnames({
                      "is-invalid": errors.email,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Digite sua senha"
                    type="password"
                    autoComplete="new-password"
                    id="password"
                    name="password"
                    className={classnames({
                      "is-invalid": errors.password,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirme sua senha"
                    type="password"
                    autoComplete="new-password"
                    id="password_confirmation"
                    name="password_confirmation"
                    className={classnames({
                      "is-invalid": errors.password_confirmation,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.password_confirmation && (
                  <small className="text-danger">
                    {errors.password_confirmation.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-birthday-cake" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="date"
                    id="birth_date"
                    name="birth_date"
                    className={classnames({
                      "is-invalid": errors.birth_date,
                    })}
                    innerRef={register({ required: true })}
                  />
                </InputGroup>
                {errors.birth_date && (
                  <small className="text-danger">
                    {errors.birth_date.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-mobile" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="tel"
                    placeholder="Digite seu celular"
                    id="mobile"
                    name="mobile"
                    className={classnames({
                      "is-invalid": errors.mobile,
                    })}
                    innerRef={register({ required: true })}
                    onChange={masks.phoneMask.onChange}
                  />
                </InputGroup>
                {errors.mobile && (
                  <small className="text-danger">{errors.mobile.message}</small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit" block>
                  Criar conta
                </Button>
              </div>
            </Form>
            <p className="text-center mt-3">
              <span className="mr-2">Já tem uma conta?</span>
              <Link to="/login">
                <span>Faça seu login</span>
              </Link>
            </p>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
