import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import CreateCustomerFormData from "./CreateCustomerFormData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  isValidCEP,
  isValidCNPJ,
  isValidCPF,
  isValidPhone,
} from "@brazilian-utils/brazilian-utils";
import * as masks from "../../utils/Mask";
import { LoadingContext } from "../../context/loading/LoadingContext";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";

export default function useCreateCustomerForm() {
  const history = useHistory();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required("Nome é obrigatório")
          .matches(
            /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|\s]+$/,
            "Nome só pode conter letras"
          ),
        email: yup
          .string()
          .email("Por favor inclua um email válido")
          .required("Email é obrigatório"),
        document: yup
          .string()
          .required("CPF/CNPJ é obrigatório")
          .transform(masks.cpfOrCnpjMask.transform)
          .test("validateCpfOrCnpj", "CPF/CNPJ inválido", (value) => {
            if (!value) {
              return false;
            }

            if (value.length === 11) {
              return isValidCPF(value);
            }

            if (value.length === 14) {
              return isValidCNPJ(value);
            }

            return false;
          }),
        birth_date: yup.string().required("Data de Nascimento é obrigatório"),
        mobile: yup
          .string()
          .required("Celular é obrigatório")
          .transform(masks.phoneMask.transform)
          .test("validatePhone", "Celular inválido", (value) => {
            if (!value) {
              return false;
            }

            return isValidPhone(value);
          }),
        zipcode: yup
          .string()
          .required("CEP é obrigatório")
          .transform(masks.cpfMask.transform)
          .test("validateCep", "CEP inválido", (value) => {
            if (!value) {
              return false;
            }

            return isValidCEP(value);
          }),
        street: yup.string().required("Endereço é obrigatório"),
        number: yup.string().required("Número é obrigatório"),
        district: yup.string().required("Bairro é obrigatório"),
        city: yup.string().required("Cidade é obrigatório"),
        state: yup
          .string()
          .required("Estado é obrigatório")
          .max(2, "Estado deve ter no máximo 2 caracteres"),
        complement: yup.string().notRequired(),
      }),
    []
  );

  const { register, handleSubmit, errors, trigger, setValue, reset } =
    useForm<CreateCustomerFormData>({
      resolver: yupResolver(validationSchema),
    });

  const onSubmit = useCallback(
    async (formValues: CreateCustomerFormData) => {
      showLoader();

      if (formValues.document.length === 11) {
        formValues.documentType = 2;
      } else if (formValues.document.length === 14) {
        formValues.documentType = 1;
      }

      try {
        await api.post("customers", JSON.stringify(formValues), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess("Cliente criado com sucesso");

        history.push("/admin/customers");
      } catch (error) {
        hideLoader();
        let message = "";

        if (error.response.data.errors) {
          error.response.data.errors.forEach((e: any) => {
            toastError(e.msg);
          });
          return;
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Algo deu de errado ao cadastrar o cliente";
        }

        toastError(message);
      }
    },
    [history, showLoader, hideLoader]
  );

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    trigger,
    setValue,
    reset,
  };
}
