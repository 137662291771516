import React, { useEffect, useState, useContext } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import Pagination from "../../components/Pagination";
import { AuthContext } from "../../context/auth/AuthContext";
import { LoadingContext } from "../../context/loading/LoadingContext";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";

const LIMIT = 10;

interface IUsers {
  iduser: number;
  name: string;
  email: string;
  active: string;
  created_at: string;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<Array<IUsers>>([]);
  const [offset, setOffset] = useState(1);
  const [pages, setPages] = useState<Array<number>>([]);

  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Usuários | Paypi";
  }, []);

  useEffect(() => {
    async function getUsers() {
      showLoader();

      try {
        const response = await api.get(`users?page=${offset}&limit=${LIMIT}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        setUsers(response.data.users);

        const totalPages = Math.ceil(response.data.totalItems / LIMIT);

        const arrayPages = [];
        for (let i = 1; i <= totalPages; i++) {
          arrayPages.push(i);
        }

        setPages(arrayPages);

        hideLoader();
      } catch (e) {
        hideLoader();
        toastError(
          "Ops, tivemos um erro na busca dos usuários, por favor tente novamente!"
        );
      }
    }

    if (user && user.role === "master") {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, offset]);

  async function handleActiveOrDeactivate(
    status: string,
    iduser: number,
    position: number
  ) {
    try {
      showLoader();
      setUsers((prevState) => {
        const newItems = [...prevState];
        newItems[position].active = status;
        return newItems;
      });

      const response = await api.post(
        `users/${iduser}/activate`,
        JSON.stringify({ active: status }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      toastSuccess(response.data.message);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(e.message.message);
    }
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Usuários cadastrados</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ativa/Desativar</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item: IUsers, index) => (
                      <tr key={item.iduser}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">{item.name}</span>
                            </Media>
                          </Media>
                        </th>
                        <td>{item.email}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={`${
                                item.active === "A"
                                  ? "bg-success"
                                  : item.active === "W"
                                  ? "bg-warning"
                                  : "bg-danger"
                              }`}
                            />
                            {item.active === "A"
                              ? "Ativo"
                              : item.active === "W"
                              ? "Aguardando"
                              : "Desativado"}
                          </Badge>
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              handleActiveOrDeactivate(
                                item.active === "A" ? "D" : "A",
                                item.iduser,
                                index
                              );
                            }}
                            className={`btn ${
                              item.active === "A" ? "btn-danger" : "btn-success"
                            } btn-block`}
                          >
                            {item.active === "A" ? "Desativar" : "Ativar"}
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {pages.length > 1 && (
                <CardFooter className="py-4">
                  <Pagination
                    pages={pages}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
