import { useState } from "react";
import api from "../services/api";
import { getToken } from "../utils/Auth";

type Group = {
  idgroup: number;
  name: string;
};

export default function useGroups() {
  const [groups, setGroups] = useState<Group[]>([]);

  async function getGroups() {
    try {
      const groups = await api.get("groups/all", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setGroups(groups.data);
    } catch (error) {}
  }

  return { groups, getGroups };
}
