import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ResetPasswordFormData } from "./ResetPasswordFormData";
import api from "../../services/api";
import { LoadingContext } from "../../context/loading/LoadingContext";
import { useHistory } from "react-router-dom";
import { toastError, toastSuccess } from "../../utils/toast";

export function useResetPasswordForm() {
  const params = new URLSearchParams(window.location.search);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const history = useHistory();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .required("Senha é obrigatório")
          .matches(
            /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "A senha deve conter pelo menos 6 caracteres, uma maiúscula, um número e um caractere especial"
          ),
        password_confirmation: yup.string().test({
          name: "password_confirmation",
          message: "As senha não são iguais",
          test: function () {
            const { password, password_confirmation } = this.parent;
            if (password && password_confirmation !== password) {
              return false;
            }
            return true;
          },
        }),
      }),
    []
  );

  const { register, handleSubmit, errors } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(async (formValues: ResetPasswordFormData) => {
    try {
      showLoader();

      const data = JSON.stringify({
        token: params.get("token"),
        password: formValues.password,
        password_confirmation: formValues.password_confirmation,
      });

      const response = await api.post("reset", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      hideLoader();

      toastSuccess(response.data.message);

      history.push("/login");
    } catch (error) {
      hideLoader();

      let message = null;

      message = "Algo deu errado ao resetar sua senha.";

      if (error.response) {
        if (error.response.data.message) {
          message = error.response.data.message;
        } else if (typeof error.response.data.errors === "object") {
          message = error.response.data.errors;

          message.forEach((e: any) => {
            toastError(e.description);
          });

          return;
        } else {
          message = "Algo deu errado ao resetar sua senha.";
        }
      }

      toastError(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
}
