import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import * as masks from "../../utils/Mask";
import { useCreateEstablishmentForm } from "./useCreateEstablishmentForm";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";

type Group = {
  idgroup: number;
  name: string;
};

export const CreateEstablishment: React.FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const { register, onSubmit, errors } = useCreateEstablishmentForm();

  async function getGroups() {
    try {
      const groups = await api.get("groups/all", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setGroups(groups.data);
    } catch (error) { }
  }

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">Criação do Estabelecimento</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Insira os detalhes do Estabelecimento
                  </h6>
                  <FormGroup>
                    <Label className="form-label" for="company_name">
                      Nome Comercial
                    </Label>
                    <Input
                      type="text"
                      name="company_name"
                      id="company_name"
                      innerRef={register({ required: true })}
                      className={classnames({
                        "is-invalid": errors.company_name,
                      })}
                    />
                    {errors && errors.company_name && (
                      <FormFeedback>{errors.company_name.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <Row>
                    <FormGroup tag={Col} lg="4">
                      <Label className="form-label" for="document_number">
                        CPF/CNPJ
                      </Label>
                      <Input
                        type="tel"
                        name="document_number"
                        id="document_number"
                        onChange={masks.cpfOrCnpjMask.onChange}
                        innerRef={register({ required: true })}
                        className={classnames({
                          "is-invalid": errors.document_number,
                        })}
                      />
                      {errors && errors.document_number && (
                        <FormFeedback>
                          {errors.document_number.message}
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup tag={Col} lg="8">
                      <Label className="form-label" for="social_reason">
                        Razão Social
                      </Label>
                      <Input
                        type="text"
                        name="social_reason"
                        id="social_reason"
                        innerRef={register({ required: true })}
                        className={classnames({
                          "is-invalid": errors.social_reason,
                        })}
                      />
                      {errors && errors.social_reason && (
                        <FormFeedback>
                          {errors.social_reason.message}
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Row>
                  <FormGroup>
                    <Label className="form-label" for="access_token">
                      Chave de Acesso da Phoebus
                    </Label>
                    <Input
                      type="text"
                      name="access_token"
                      id="access_token"
                      innerRef={register({ required: true })}
                      className={classnames({
                        "is-invalid": errors.access_token,
                      })}
                    />
                    {errors && errors.access_token && (
                      <FormFeedback>{errors.access_token.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label" for="idgroup">
                      Grupo econômico
                    </Label>
                    <Input
                      type="select"
                      name="idgroup"
                      id="idgroup"
                      innerRef={register({ required: true })}
                      className={classnames({ "is-invalid": errors.idgroup })}
                    >
                      <option value="">Selecione uma opção</option>
                      {groups.map((group) => (
                        <option key={group.idgroup} value={group.idgroup}>
                          {group.name}
                        </option>
                      ))}
                    </Input>
                    {errors && errors.idgroup && (
                      <FormFeedback>{errors.idgroup.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleCheckbox">Adquirente</Label>
                    <div>
                      <div className="d-none">
                        <input
                          type="checkbox"
                          id="cielo"
                          name="acquirers"
                          className={`custom-control-input ${classnames({
                            "is-invalid": errors.acquirers,
                          })}`}
                          value="CIELO"
                          ref={register({ required: true })}
                        />
                        <label className="custom-control-label" htmlFor="cielo">
                          Cielo
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox custom-control-inline">
                        <input
                          type="checkbox"
                          id="phoebus"
                          name="acquirers"
                          className={`custom-control-input ${classnames({
                            "is-invalid": errors.acquirers,
                          })}`}
                          value="PHOEBUS"
                          ref={register({ required: true })}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="phoebus"
                        >
                          PHOEBUS
                        </label>
                      </div>
                    </div>
                    {errors && errors.acquirers && (
                      <p className="invalid-feedback d-block">{errors.acquirers.message}</p>
                    )}
                  </FormGroup>

                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-check" /> Finalizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
