import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  formatCNPJ,
  formatCPF,
  isValidCNPJ,
  isValidCPF,
} from "@brazilian-utils/brazilian-utils";
import { useHistory } from "react-router-dom";

import { CreateEstablishmentFormData } from "../CreateEstablishment/CreateEstablishmentFormData";
import * as masks from "../../utils/Mask";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError, toastSuccess } from "../../utils/toast";
import useLoading from "../../hooks/useLoading";
import { useUpdateEstablishmentData } from "./useUpdateEstablishmentData";

export function useUpdateEstablishmentForm(companyId: number) {
  const history = useHistory();
  const [idgroup, setGroup] = useState("");
  const { showLoader, hideLoader } = useLoading();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        company_name: yup.string().required("Nome Comercial é obrigatório"),
        social_reason: yup.string().required("Razão Social é obrigatório"),
        document_number: yup
          .string()
          .required("CPF/CNPJ é obrigatório")
          .transform(masks.cpfOrCnpjMask.transform)
          .test("validateCpfOrCnpj", "CPF/CNPJ inválido", (value) => {
            if (!value) {
              return false;
            }

            if (value.length === 11) {
              return isValidCPF(value);
            }

            if (value.length === 14) {
              return isValidCNPJ(value);
            }

            return false;
          }),
        access_token: yup.string().notRequired(),
        idgroup: yup.string().required("Grupo econômico é obrigatório"),
        acquirers: yup
          .array()
          .of(
            yup.string().required("Adquirente é obrigatório").oneOf(["PHOEBUS"])
          )
          .min(1, "Adquirente é obrigatório"),
      }),
    []
  );

  const { register, handleSubmit, errors, reset } =
    useForm<CreateEstablishmentFormData>({
      resolver: yupResolver(validationSchema),
    });

  const handleDefaultValues = useCallback(
    (establishments: CreateEstablishmentFormData) => {
      let document_number = "";

      if (establishments?.document_number.length === 11) {
        document_number = formatCPF(establishments.document_number);
      } else if (establishments?.document_number.length === 14) {
        document_number = formatCNPJ(establishments.document_number);
      }

      const defaultValues: CreateEstablishmentFormData = {
        company_name: establishments?.company_name || "",
        document_number: document_number || "",
        social_reason: establishments?.social_reason || "",
        access_token: establishments?.access_token || "",
        idgroup: establishments?.idgroup || "",
      };

      reset(defaultValues);
      setGroup(defaultValues.idgroup);
    },
    [reset]
  );

  const { establishments } = useUpdateEstablishmentData(
    companyId,
    handleDefaultValues
  );

  const onSubmit = useCallback(
    async (formValues: CreateEstablishmentFormData) => {
      try {
        showLoader();

        await api.put(`companies/${companyId}`, JSON.stringify(formValues), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess("Estabelecimento atualizado com sucesso.");

        history.push("/admin/establishments");
      } catch (error) {
        hideLoader();
        let message = "";

        if (error.response.data.errors) {
          error.response.data.errors.forEach((e: any) => {
            toastError(e.msg);
          });
          return;
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Algo deu errado ao atualizar o Estabelecimento";
        }

        toastError(message);
      }
    },
    [history, companyId, showLoader, hideLoader]
  );

  return {
    register,
    errors,
    establishments,
    idgroup,
    setGroup,
    onSubmit: handleSubmit(onSubmit),
  };
}
