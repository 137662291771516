import React, { useContext } from "react";
import { LoadingContext } from "../../context/loading/LoadingContext";

import "./styles.scss";

const Loading = () => {
  const loaderContext = useContext(LoadingContext);

  const { loading } = loaderContext;

  return (
    <div>
      {loading ? (
        <div id="modal-root">
          <div className="spinner-box">
            <div className="loading-icon" />
            <span>Aguarde...</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Loading;
