import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  CardFooter,
} from "reactstrap";
import Pagination from "react-responsive-pagination";

import CustomerHeader from "../../components/Headers/CustomerHeader";
import useAuth from "../../hooks/useAuth";
import useLoading from "../../hooks/useLoading";
import api from "../../services/api";
import { getToken } from "../../utils/Auth";
import { toastError } from "../../utils/toast";

type GroupUser = {
  idgroup: number;
  iduser: number;
  user: {
    iduser: number;
    name: string;
    email: string;
    mobile: string;
    birth_date: string;
    active: string;
  };
};

type IGroups = {
  idgroup: number;
  name: string;
  active: string;
  bg_status: string;
  status: string;
  group_user: GroupUser[];
};

const LIMIT = 15;

export const Groups: React.FC = () => {
  const { user } = useAuth();
  const { showLoader, hideLoader } = useLoading();
  const [groups, setGroups] = useState<IGroups[]>([]);
  const [pages, setPages] = useState(0);
  const [offset, setOffset] = useState(1);

  const getGroups = useCallback(async () => {
    try {
      showLoader();
      const response = await api.get(`groups?page=${offset}&limit=${LIMIT}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      let groups: IGroups[] = [];

      const totalPages = Math.ceil(response.data.totalItems / LIMIT);

      setPages(totalPages);

      if (response.data.groups.length) {
        groups = response.data.groups.map((seller: IGroups) => {
          seller.bg_status = "bg-success";
          seller.status = "Ativo";

          if (seller.active === "W") {
            seller.bg_status = "bg-warning";
            seller.status = "Aguardando";
          } else if (seller.active === "D") {
            seller.bg_status = "bg-danger";
            seller.status = "Desativado";
          }

          return seller;
        });
      }

      setGroups(groups);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError("Algo deu errado ao buscar os Grupos Econômicos");
    }
  }, [hideLoader, offset, showLoader]);

  useEffect(() => {
    document.title = "Grupo Econômico | Paypi";
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [offset]);

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <div className="text-right">
              {user && user.role === "master" && (
                <Link
                  className="btn btn-default mb-3 mt-2"
                  to="/admin/groups/create"
                >
                  <i className="fas fa-plus" /> Criar grupo
                </Link>
              )}
            </div>

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Grupo Econômico</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col" className="text-white">
                      Nome
                    </th>
                    <th scope="col" className="text-white">
                      Representante
                    </th>
                    <th scope="col" className="text-white">
                      Status
                    </th>
                    <th scope="col" className="text-white">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {groups.map((group) => (
                    <tr key={group.idgroup}>
                      <td>{group.name}</td>
                      <td>{group.group_user[0].user.name}</td>
                      <td>
                        <span
                          className={`${group.bg_status} text-white p-1 px-3 rounded text-center`}
                        >
                          {group.status}
                        </span>
                      </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {user && user.role !== "common" ? (
                              <Link
                                to={`/admin/groups/${group.idgroup}`}
                                className="dropdown-item"
                                tabIndex={0}
                                role="menuitem"
                              >
                                <span>
                                  <i className="fas fa-edit" /> Editar
                                </span>
                              </Link>
                            ) : (
                              <DropdownItem disabled>
                                <span>
                                  <i className="fas fa-edit" /> Editar
                                </span>
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={pages}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
