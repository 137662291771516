import React, { useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import Wizard from "../../components/Wizard/index";
import FinishPayment from "./steps/FinishPayment";
import PaymentData from "./steps/PaymentData";
import useCreateSalesForm from "./useCreateSalesForm";

const CreateSales = () => {
  const [stepper, setStepper] = useState(null);
  const [sales, setSales] = useState({});
  const ref = useRef(null);
  const [finishPayment, setFinishPayment] = useState({
    amount: 0,
    installments: 1,
    status: 3,
    message: "",
  });

  const { onSubmit } = useCreateSalesForm();

  const updateSalesData = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value !== "undefined") {
        setSales((oldState) => {
          const newItems = { ...oldState };
          newItems[key] = value;
          return newItems;
        });
      }
    });
  };

  const handleOnSubmit = async (data) => {
    const dataSales = { ...sales, ...data };
    await onSubmit(dataSales, stepper, setFinishPayment);
  };

  const steps = [
    {
      id: "personal-info",
      title: "Dados do Pagamento",
      subtitle: "",
      content: (
        <PaymentData stepper={stepper} paymentData={updateSalesData} submitData={handleOnSubmit} />
      ),
    },
    {
      id: "payment-status",
      title: "Status do Pagamento",
      subtitle: "",
      content: (
        <FinishPayment stepper={stepper} finishPayment={finishPayment} />
      ),
    },
  ];

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <div className="horizontal-wizard">
              <Wizard
                instance={(el) => setStepper(el)}
                ref={ref}
                steps={steps}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateSales;
